// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';
import type {
  DataImportConfiguration,
  DataImportJobSummary,
  SaveDataImportConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import type { ApiError } from '../../../../../api/types';
import { AddImportJobRequest } from '../containers/create-import-job/utils/models';

export const dataImportConfigurationGetActions = createAsyncAction(
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_REQUEST',
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_SUCCESS',
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_FAILURE',
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_CANCEL',
)<void, DataImportConfiguration | null, ApiError, string | void>();

export const dataImportConfigurationSaveActions = createAsyncAction(
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_REQUEST',
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_SUCCESS',
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_FAILURE',
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_CANCEL',
)<
  SaveDataImportConfigurationRequest,
  DataImportConfiguration | null,
  ApiError,
  string | void
>();

export const importJobAddActions = createAsyncAction(
  'integrations/dataImportConfiguration/system/ADD_DATA_IMPORT_JOB_REQUEST',
  'integrations/dataImportConfiguration/system/ADD_DATA_IMPORT_JOB_SUCCESS',
  'integrations/dataImportConfiguration/system/ADD_DATA_IMPORT_JOB_FAILURE',
  'integrations/dataImportConfiguration/system/ADD_DATA_IMPORT_JOB_CANCEL',
)<AddImportJobRequest, void, ApiError, string | void>();

export const importJobsSummaryGetActions = createAsyncAction(
  'integrations/dataImportConfiguration/system/GET_IMPORT_JOBS_SUMMARY_REQUEST',
  'integrations/dataImportConfiguration/system/GET_IMPORT_JOBS_SUMMARY_SUCCESS',
  'integrations/dataImportConfiguration/system/GET_IMPORT_JOBS_SUMMARY_FAILURE',
  'integrations/dataImportConfiguration/system/GET_IMPORT_JOBS_SUMMARY_CANCEL',
)<void, DataImportJobSummary[] | null, ApiError, string | void>();
