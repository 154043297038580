// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './StateTag.module.less';
import clsx from 'clsx';

type Props = React.ComponentProps<typeof Tag> & { state: string };

const JobStateTag: React.FC<Props> = ({ state, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Tag
      className={clsx(classes.jobState, classes[state.toLowerCase()])}
      {...rest}
    >
      {t(
        `data-import-configuration.jobs-table.job-state.${state.toLowerCase()}`,
      )}
    </Tag>
  );
};

export default JobStateTag;
