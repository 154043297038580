// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SaveDataImportConfigurationRequest } from '@imprivata-cloud/adminapi-client';
import type { Dispatch } from 'react';
import {
  importJobAddActions,
  dataImportConfigurationGetActions,
  dataImportConfigurationSaveActions,
  importJobsSummaryGetActions,
} from './actions';
import { AddImportJobRequest } from '../containers/create-import-job/utils/models';

export const saveDataImportConfiguration = (
  request: SaveDataImportConfigurationRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(dataImportConfigurationSaveActions.request(request));
};

export const getDataImportConfiguration = (dispatch: Dispatch<any>): void => {
  dispatch(dataImportConfigurationGetActions.request());
};

export const getImportJobsSummary = (dispatch: Dispatch<any>): void => {
  dispatch(importJobsSummaryGetActions.request());
};

export const addImportJob = (
  request: AddImportJobRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(importJobAddActions.request(request));
};
