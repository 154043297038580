// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { FileParameter } from '@imprivata-cloud/adminapi-client';

export enum ImportType {
  PatientList = 'PatientAccessEnrollmentFromFile',
}

export interface AddImportJobRequest {
  file: FileParameter;
  displayName: string;
  importType: string;
  consentGranted: boolean;
  patientIdentifierOid: string;
  processingStartingWindow: moment.Moment | null;
  processingEndingWindow: moment.Moment | null;
}
