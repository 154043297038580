// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React from 'react';
import PageSubHeader from '../../../../../../components/page-sub-header/PageSubHeader';
import { Breadcrumb } from 'antd';
import { getPathWithQuery } from '../../../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../../../routers/route-names';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ImportJobComponent: React.FC = () => {
  const currentSearchParams = new URLSearchParams(location.search);
  const jobId = currentSearchParams.get('jobId');
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb style={{ marginBottom: '10px' }}>
        <Breadcrumb.Item>
          <Link to={getPathWithQuery(integrationsRoutes.DATA_IMPORT)}>
            {t('navigation.data-import-configuration')}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {t('data-import-configuration.job-details.title', {
            name: jobId,
          })}
        </Breadcrumb.Item>
      </Breadcrumb>

      <PageSubHeader
        title={t('data-import-configuration.job-details.title', {
          name: jobId,
        })}
      />
    </>
  );
};

export default ImportJobComponent;
