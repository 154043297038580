// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
export interface ApiError {
  code: string;
  message?: string;
  data?: unknown;
}

export interface IdentifierDecrypted {
  system?: string | null;
  value?: string | null;
}

export class IdentifierDecrypted implements IdentifierDecrypted {
  system?: string | null;
  value?: string | null;
  constructor(data?: IdentifierDecrypted) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface PatientDecrypted {
  id?: string;
  givenNames?: string[] | null;
  surname?: string | null;
  dateOfBirth?: string | null;
  gender?: string | null;
  identifiers?: IdentifierDecrypted[] | null;
  phoneNumbers?: string[] | null;
  emailAddresses?: string[] | null;
  createdTimestamp?: moment.Moment;
  updatedTimestamp?: moment.Moment | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  photo?: string | null;
  photoTakenDate?: string | null;
  squarePhoto?: string | null;
}

export class PatientDecrypted implements PatientDecrypted {
  id?: string;
  givenNames?: string[] | null;
  surname?: string | null;
  dateOfBirth?: string | null;
  gender?: string | null;
  identifiers?: IdentifierDecrypted[] | null;
  phoneNumbers?: string[] | null;
  emailAddresses?: string[] | null;
  createdTimestamp?: moment.Moment;
  updatedTimestamp?: moment.Moment | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  photo?: string | null;
  photoTakenDate?: string | null;
  squarePhoto?: string | null;
  constructor(data?: PatientDecrypted) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface PatientDemographicHistoryDecrypted {
  demographicsHistory?: PatientDemographicHistoryItemDecrypted[] | null;
}
export interface PatientDemographicHistoryItemDecrypted {
  patientId?: string | null;
  updatedField?: string | null;
  updatedTimestamp?: moment.Moment | string | null;
  oldValue?: string | null;
  newValue?: string | null;
  updatedBy?: string | null;
}

export interface PatientSearchResponseDecrypted {
  patients?: PatientDecrypted[] | null;
}

export class PatientSearchResponseDecrypted
  implements PatientSearchResponseDecrypted
{
  patients?: PatientDecrypted[] | null;
  constructor(data?: PatientSearchResponseDecrypted) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface PatientHl7MessageSummaryDecrypted {
  messageId?: string | null;
  eventDisplayName?: string | null;
  createdTimestamp?: moment.Moment | null;
  sentTimestamp?: moment.Moment | null;
  messageStatus?: string | null;
}

export class PatientHl7MessageSummaryDecrypted
  implements PatientHl7MessageSummaryDecrypted
{
  messageId?: string | null;
  eventDisplayName?: string | null;
  createdTimestamp?: moment.Moment | null;
  sentTimestamp?: moment.Moment | null;
  messageStatus?: string | null;

  constructor(data?: PatientHl7MessageSummaryDecrypted) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface ActivityDecrypted {
  date?: moment.Moment;
  activityType?: string | null;
  activitySubtype?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
}

export class ActivityDecrypted implements ActivityDecrypted {
  date?: moment.Moment;
  activityType?: string | null;
  activitySubtype?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;

  constructor(data?: ActivityDecrypted) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface ActivityHistoryResponseDecrypted {
  activities?: ActivityDecrypted[] | null;
}

export class ActivityHistoryResponseDecrypted
  implements ActivityHistoryResponseDecrypted
{
  activities?: ActivityDecrypted[] | null;
  constructor(data?: ActivityHistoryResponseDecrypted) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export enum FactorTypeEnum {
  'PWD' = 'pwd',
}

export enum ReportTypeEnum {
  ActivityExtract = 'ActivityExtract',
  AuditExtract = 'AuditExtract',
}

export interface ReportDate {
  date: moment.Moment | null;
  dateString: string | null;
}

export interface MappedChartDatum {
  activityType: string | null | undefined;
  label: string | null | undefined;
  count: number | undefined;
}

export type CreateSessionPayload = {
  tenantId: string;
  sessionId: string;
};
