// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { Col, Row, Table, Empty, Skeleton } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FilterFilled } from '@ant-design/icons';
import type {
  PatientDecrypted,
  PatientDemographicHistoryItemDecrypted,
} from 'src/api/types';
import classes from './PatientDemographics.module.less';
import { usePatientSearch } from '../../../patient-search/store/hooks';
import { fetchDemographicsHistory } from '../../../patient-search/store/facades';
import {
  useFilteringAndSorting,
  usePatientDetailsPagination,
} from '../../store/hooks';
interface DemographicUpdate {
  key: string;
  date: string;
  dataUpdated: string;
  previousValue: string;
  newValue: string;
}

interface PatientDemographicUpdatesTableProps {
  patient: PatientDecrypted | null;
  demographicUpdates: PatientDemographicHistoryItemDecrypted[] | null;
  paginationComponent: JSX.Element;
  setLatestTableRecordDateTime: (dateTime: string) => void;
}

const PatientDemographicUpdatesTable: React.FC<
  PatientDemographicUpdatesTableProps
> = ({
  patient,
  demographicUpdates,
  paginationComponent,
  setLatestTableRecordDateTime,
}) => {
  const { t } = useTranslation();
  const { isLoadingDemographicsHistory } = usePatientSearch();
  const {
    pagination: paginationDetails,
    updatePagination,
    currentCount,
  } = usePatientDetailsPagination();
  const dispatch = useDispatch();

  const { filters, sorter, updateFilters, updateSorter } =
    useFilteringAndSorting({
      tableName: 'demographicUpdates',
    });

  useEffect(() => {
    if (patient?.id && demographicUpdates === null) {
      fetchDemographicsHistory(patient?.id, dispatch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: DemographicUpdate[] =
    demographicUpdates
      ?.map((item, index) => {
        let formattedDate = '';
        if (item.updatedTimestamp) {
          if (typeof item.updatedTimestamp === 'string') {
            // Convert the string to a moment object, set it to local time, and format it
            formattedDate = moment(item.updatedTimestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss');
          } else {
            // Assume it's already a moment object, set it to local time, and format it
            formattedDate = item.updatedTimestamp
              .local()
              .format('YYYY-MM-DD HH:mm:ss');
          }
        }
        return {
          key: index.toString(),
          date: formattedDate || 'N/A',
          dataUpdated: item.updatedField ? item.updatedField : 'N/A',
          previousValue: item.oldValue ? item.oldValue : 'N/A',
          newValue: item.newValue ? item.newValue : 'N/A',
        };
      })
      .sort(
        (du1, du2) =>
          new Date(du2.date).getTime() - new Date(du1.date).getTime(),
      ) ?? [];

  useEffect(() => {
    setLatestTableRecordDateTime(data[0]?.date ?? '');
  }, [data]);

  if (isLoadingDemographicsHistory) {
    return <Skeleton style={{ margin: '30px 0' }} active />;
  }

  return (
    <>
      {paginationComponent}
      <Table
        dataSource={data}
        rowKey="key"
        data-testid="patient-demographic-updates-table"
        onChange={(pagination, filters, sorter, extra) => {
          updateFilters(filters);
          updateSorter(sorter);
          updatePagination({
            ...paginationDetails,
            pageNumber:
              currentCount.totalCount !== extra.currentDataSource.length
                ? 1
                : paginationDetails.pageNumber,
            totalCount: extra.currentDataSource.length,
          });
        }}
        className={classes.antPagination}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          current: paginationDetails.pageNumber,
          pageSize: paginationDetails.pageSize,
          showSizeChanger: true,
          onChange: (pageNumber, pageSize) => {
            updatePagination({
              activeTab: '2',
              pageNumber,
              pageSize,
            });
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('patient-details.no-table-data')}
            />
          ),
        }}
        tableLayout="fixed"
      >
        <Table.Column<DemographicUpdate>
          title={() => (
            <span data-testid="patient-demographic-updates-table--sortable-column--header-date">
              {t('patient-details.demographic-updates.date')}
            </span>
          )}
          dataIndex="date"
          key="date"
          onHeaderCell={() => ({
            className: classes.tableHeader,
          })}
          sorter={{
            compare: (du1, du2) =>
              new Date(du1.date).getTime() - new Date(du2.date).getTime(),
          }}
          sortOrder={sorter?.columnKey === 'date' ? sorter?.order : undefined}
          render={(_, record: DemographicUpdate) => (
            <Row data-testid={`patient-demographics--row-${record.key}`}>
              {moment(record.date)?.format('YYYY-MM-DD HH:mm') ?? ''}
            </Row>
          )}
        />
        <Table.Column<DemographicUpdate>
          title={t('patient-details.demographic-updates.data-updated')}
          dataIndex="dataUpdated"
          key="dataUpdated"
          onHeaderCell={() => ({
            className: classes.tableHeader,
          })}
          filteredValue={filters?.dataUpdated || null}
          filters={data
            .filter(
              (currentOption, index, self) =>
                index ===
                self.findIndex(
                  dataOption =>
                    dataOption.dataUpdated === currentOption.dataUpdated,
                ),
            )
            .map(demographicUpdate => ({
              text: demographicUpdate.dataUpdated,
              value: demographicUpdate.dataUpdated,
            }))}
          filterSearch={true}
          filterIcon={filtered => (
            <FilterFilled
              data-testid="patient-demographic-updates--data-updated-filter"
              className={
                filtered ? classes.filterSelected : classes.filterUnselected
              }
            />
          )}
          onFilter={(value, record) => record.dataUpdated === value}
          render={(_, record: DemographicUpdate) => (
            <Row
              data-testid={`patient-demographics--dataUpdated-${record.key}`}
            >
              {record.dataUpdated}
            </Row>
          )}
        />
        <Table.Column
          title={t('patient-details.demographic-updates.previous-value')}
          dataIndex="previousValue"
          key="previousValue"
          onHeaderCell={() => ({
            className: classes.tableHeader,
          })}
          render={(_, record: DemographicUpdate) => (
            <Row
              data-testid={`patient-demographics--previousValue-${record.key}`}
            >
              {record.previousValue}
            </Row>
          )}
        />
        <Table.Column
          title={t('patient-details.demographic-updates.new-value')}
          dataIndex="newValue"
          key="newValue"
          onHeaderCell={() => ({
            className: classes.tableHeader,
          })}
          render={(_, record: DemographicUpdate) => (
            <Row data-testid={`patient-demographics--newValue-${record.key}`}>
              <Col>{record.newValue}</Col>
            </Row>
          )}
        />
      </Table>
    </>
  );
};

export default PatientDemographicUpdatesTable;
