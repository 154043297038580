// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type {
  DataImportConfiguration,
  DataImportJobSummary,
} from '@imprivata-cloud/adminapi-client';
import type { RootAction } from '../../../../../store/rootAction';
import {
  dataImportConfigurationGetActions,
  dataImportConfigurationSaveActions,
  importJobAddActions,
  importJobsSummaryGetActions,
} from './actions';
import { invalidSessionAction } from '../../../../login/store/actions';

export interface DataImportConfigurationState {
  loading: boolean;
  jobsSummaryLoading: boolean;
  savingJob: boolean;
  error: string | null;
  configuration: DataImportConfiguration | null;
  dataImportJobsSummary: DataImportJobSummary[] | null;
}

export const initialState: DataImportConfigurationState = {
  loading: false,
  jobsSummaryLoading: false,
  savingJob: false,
  error: null,
  configuration: null,
  dataImportJobsSummary: null,
};

export const dataImportConfigurationReducer =
  combineReducers<DataImportConfigurationState>({
    loading: createReducer<boolean>(initialState.loading)
      .handleAction(
        [
          dataImportConfigurationGetActions.request,
          dataImportConfigurationSaveActions.request,
        ],
        () => true,
      )
      .handleAction(
        [
          dataImportConfigurationGetActions.cancel,
          dataImportConfigurationGetActions.success,
          dataImportConfigurationGetActions.failure,
          dataImportConfigurationSaveActions.cancel,
          dataImportConfigurationSaveActions.success,
          dataImportConfigurationSaveActions.failure,
          invalidSessionAction.request,
        ],
        () => false,
      ),
    jobsSummaryLoading: createReducer<boolean>(initialState.jobsSummaryLoading)
      .handleAction([importJobsSummaryGetActions.request], () => true)
      .handleAction(
        [
          importJobsSummaryGetActions.cancel,
          importJobsSummaryGetActions.success,
          importJobsSummaryGetActions.failure,
          invalidSessionAction.request,
        ],
        () => false,
      ),
    savingJob: createReducer<boolean>(initialState.savingJob)
      .handleAction([importJobAddActions.request], () => true)
      .handleAction(
        [
          importJobAddActions.cancel,
          importJobAddActions.success,
          importJobAddActions.failure,
          invalidSessionAction.request,
        ],
        () => false,
      ),
    configuration: createReducer<DataImportConfiguration | null, RootAction>(
      initialState.configuration,
    )
      .handleAction(
        [dataImportConfigurationGetActions.success],
        (_, { payload }) => {
          return !payload
            ? null
            : {
                clientId: payload.clientId,
                fhirServerBaseUrl: payload.fhirServerBaseUrl,
                updatedBy: payload.updatedBy,
                updatedTimestamp: payload.updatedTimestamp,
              };
        },
      )
      .handleAction(
        [dataImportConfigurationSaveActions.success],
        (state, { payload }) => {
          return {
            ...state,
            clientId: payload?.clientId,
          };
        },
      )
      .handleAction(
        [
          invalidSessionAction.request,
          dataImportConfigurationGetActions.failure,
          dataImportConfigurationGetActions.cancel,
        ],
        () => null,
      ),
    dataImportJobsSummary: createReducer<
      DataImportJobSummary[] | null,
      RootAction
    >(initialState.dataImportJobsSummary)
      .handleAction([importJobsSummaryGetActions.success], (_, { payload }) => {
        return payload;
      })
      .handleAction(
        [
          importJobsSummaryGetActions.cancel,
          importJobsSummaryGetActions.failure,
          invalidSessionAction.request,
        ],
        () => null,
      ),
    error: createReducer<string | null, RootAction>(initialState.error)
      .handleAction(
        [
          dataImportConfigurationGetActions.request,
          dataImportConfigurationGetActions.success,
          dataImportConfigurationSaveActions.request,
          dataImportConfigurationSaveActions.success,
          invalidSessionAction.request,
        ],
        () => null,
      )
      .handleAction(
        [
          dataImportConfigurationGetActions.failure,
          dataImportConfigurationSaveActions.failure,
        ],
        (_, { payload }) => payload.code || null,
      )
      .handleAction(
        [
          dataImportConfigurationGetActions.cancel,
          dataImportConfigurationSaveActions.cancel,
        ],
        () => 'cancelled',
      ),
  });
