// Copyright 2022, Imprivata, Inc.  All rights reserved.
export const DASHBOARD_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const NOT_FOUND_ROUTE = '/not-found';
export const PATIENT_SEARCH_ROUTE = '/patients';
export const INSTALLERS_ROUTE = '/endpoint-installer';
export const INTEGRATIONS_ROUTE = '/integrations';
export const SETTINGS_ROUTE = '/settings';
export const HELP_ROUTE = '/help';
export const PATIENT_DETAILS_ROUTE = '/patient/details';
export const REPORTS_ROUTE = '/reports';

export const loginRoutes = {
  BASE: LOGIN_ROUTE,
} as const;

export const integrationsRoutes = {
  BASE: INTEGRATIONS_ROUTE,
  EMR_CONFIGURATION: `${INTEGRATIONS_ROUTE}/emr`,
  FHIR_CONFIGURATION: `${INTEGRATIONS_ROUTE}/fhir`,
  HL7_CONFIGURATION: `${INTEGRATIONS_ROUTE}/hl7`,
  HL7_SYSTEM_CONFIGURATION: `${INTEGRATIONS_ROUTE}/hl7/system`,
  HL7_RULE_CONFIGURATION: `${INTEGRATIONS_ROUTE}/hl7/rules`,
  HL7_MESSAGES: `${INTEGRATIONS_ROUTE}/hl7/messages`,
  DATA_IMPORT: `${INTEGRATIONS_ROUTE}/data-import`,
  CREATE_IMPORT_JOB: `${INTEGRATIONS_ROUTE}/data-import/job`,
  IMPORT_JOB: `${INTEGRATIONS_ROUTE}/data-import/job/details`,
} as const;

export const reportsRoutes = {
  BASE: REPORTS_ROUTE,
  ACTIVITY_EXTRACT: `${REPORTS_ROUTE}/activity-extract`,
  AUDIT_EXTRACT: `${REPORTS_ROUTE}/audit-extract`,
} as const;
