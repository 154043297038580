// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { SaveDataImportConfigurationRequest } from '@imprivata-cloud/adminapi-client';
import {
  dataImportConfigurationSelector,
  isLoadingSelector,
  dataImportJobsSummarySelector,
  isJobsSummaryLoadingSelector,
  isSavingJobSelector,
} from './selectors';
import {
  dataImportConfigurationGetActions,
  dataImportConfigurationSaveActions,
  importJobsSummaryGetActions,
} from './actions';

export const useGetDataImportConfiguration = (): {
  dataImportConfiguration: ReturnType<typeof dataImportConfigurationSelector>;
  dataImportJobsSummary: ReturnType<typeof dataImportJobsSummarySelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
  isJobsSummaryLoading: ReturnType<typeof isJobsSummaryLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const jobsSummaryLoading = useSelector(isJobsSummaryLoadingSelector);
  const dataImportConfiguration = useSelector(dataImportConfigurationSelector);
  const dataImportJobsSummary = useSelector(dataImportJobsSummarySelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(dataImportConfigurationGetActions.request());
      dispatch(importJobsSummaryGetActions.request());

      return () => {
        dispatch(dataImportConfigurationGetActions.cancel());
        dispatch(importJobsSummaryGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return {
    dataImportConfiguration,
    dataImportJobsSummary,
    isLoading: loading,
    isJobsSummaryLoading: jobsSummaryLoading,
  };
};

export const useSaveDataImportConfiguration = (
  request: SaveDataImportConfigurationRequest,
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dataImportConfigurationSaveActions.request(request));
  }, [dispatch, request]);
};

export const useGetSavingJobStatus = (): {
  isSavingJob: ReturnType<typeof isSavingJobSelector>;
} => {
  const savingJob = useSelector(isSavingJobSelector);

  return {
    isSavingJob: savingJob,
  };
};
