// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useTranslation } from 'react-i18next';
import { Col, Row, Table, Image, Typography, Spin } from 'antd';
import moment from 'moment';
import Column from 'antd/lib/table/Column';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Ellipsis } from 'antd-mobile';
import type { PatientDecrypted } from '../../../../api/types';
import { useFilteringAndSorting } from '../../../patient-details/store/hooks';
import { usePatientSearch } from '../../store/hooks';
import classes from './PatientSearchResults.module.less';
import NoPhotoSrc from '../../../../assets/svg/no-photo.svg';
import { PATIENT_DETAILS_ROUTE } from '../../../../routers/route-names';
import { patientSelect } from '../../store/facades';
import { redirectWithQuery } from '../../../../utils/routingHelpers';

const PatientSearchResults: React.FC = () => {
  const { t } = useTranslation();

  const { searchResponse, isSearching } = usePatientSearch();
  const { clearLocalStorage } = useFilteringAndSorting({
    tableName: 'activity',
  });

  const dispatch = useDispatch();

  const toDataUri = (patient: PatientDecrypted) => {
    return 'data:image/png;base64,' + patient.squarePhoto;
  };

  const localeDateString = (patient: PatientDecrypted) => {
    return moment(patient.createdTimestamp).format('YYYY-MM-DD');
  };

  const onRowClickHandler = (record: PatientDecrypted) => {
    clearLocalStorage();
    patientSelect(record, dispatch);
    redirectWithQuery(PATIENT_DETAILS_ROUTE, [`patient=${record.id}`]);
  };

  return !isSearching ? (
    <Table
      dataSource={searchResponse?.patients ?? []}
      rowKey={record => (record.id ? record.id : '')}
      locale={{ emptyText: t('patient-search.no-patients') }}
      className={classes.searchResultTable}
      rowClassName={clsx(
        'patient-search-result--result-row',
        classes.searchResultRow,
      )}
      onRow={record => ({
        onClick: () => {
          onRowClickHandler(record);
        },
      })}
    >
      <Column
        title={t('patient-search.patient')}
        dataIndex="patient"
        key="patient"
        className={`${classes.searchResultColumn} ${classes.colMd13}`}
        render={(_, patient: PatientDecrypted) => {
          const isSurnameAvailable = !!patient.surname?.length;
          const areGivenNamesAvailable = !!patient.givenNames?.length;
          const fullName =
            isSurnameAvailable || areGivenNamesAvailable
              ? `${
                  isSurnameAvailable
                    ? patient.surname
                    : 'Last name not available'
                }, ${
                  areGivenNamesAvailable
                    ? patient.givenNames?.join(' ')
                    : 'First name not available'
                }`
              : 'Name not available';
          return (
            <>
              <Row
                data-testid={`patient-search-result--row-${patient.id}`}
                className={classes.searchResultPatientInfo}
              >
                <Col>
                  <Image
                    data-testid={`patient-search-result--photo-${patient.id}`}
                    className={classes.searchResultPhoto}
                    src={toDataUri(patient)}
                    fallback={NoPhotoSrc}
                    preview={{ mask: t('patient-search.view-patient-details') }}
                  ></Image>
                </Col>
                <Col className={classes.searchResultPatientDetailsColumn}>
                  <Row
                    data-testid={`patient-search-result--patient-names-${patient.id}`}
                  >
                    <Typography.Text style={{ width: '100%' }} strong>
                      <Ellipsis content={fullName}></Ellipsis>
                    </Typography.Text>
                  </Row>
                  <Row
                    data-testid={`patient-search-result--patient-dob-${patient.id}`}
                  >
                    {patient.dateOfBirth?.length
                      ? `${moment(patient.dateOfBirth)?.format('YYYY-MM-DD')}`
                      : 'DOB not available'}
                  </Row>
                  {patient.identifiers?.map(identifier => (
                    <Row
                      data-testid={`patient-search-result--patient-identifier-${patient.id}`}
                      key={identifier.system ? identifier.system : ''}
                    >
                      MRN: {identifier.value}
                    </Row>
                  ))}
                </Col>
              </Row>
            </>
          );
        }}
      />
      <Column
        title={t('patient-search.enrollment-details')}
        dataIndex="enrollmentDetails"
        key="enrollmentDetails"
        className={`${classes.searchResultColumn} ${classes.colMd11}`}
        render={(_, patient: PatientDecrypted) => (
          <>
            <Row
              data-testid={`patient-search-result--patient-enroll-date-${patient.id}`}
            >
              {t('patient-search.enrolled-on')}
              {localeDateString(patient)}
            </Row>
            <Row
              data-testid={`patient-search-result--patient-createdBy-${patient.id}`}
              className={classes.wrapText}
            >
              {t('patient-search.by')}
              {patient.createdBy}
            </Row>
            <Row
              data-testid={`patient-search-result--patient-createdAt-${patient.id}`}
              className={classes.wrapText}
            >
              {t('patient-search.at')}
              {patient.createdAt}
            </Row>
          </>
        )}
      />
    </Table>
  ) : (
    <Spin size="large" className={classes.searchResultSpinner} />
  );
};

export default PatientSearchResults;
