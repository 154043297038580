// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import type { ActionButtonProps } from './types';
import successWhiteIcon from '../../assets/svg/success-white.svg?react';

const CreateButton: React.FC<ActionButtonProps> = function SaveButton({
  onClick,
  className,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Button
      label={t('actions.create')}
      data-testid="create-button"
      icon={<Icon component={successWhiteIcon} />}
      className={className}
      variant={ButtonVariant.PRIMARY}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default CreateButton;
