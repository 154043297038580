// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import type {
  SaveDataImportConfigurationRequest,
  DataImportConfiguration,
  GetDataImportConfigurationResponse,
  GetImportJobsSummaryResponse,
  DataImportJobSummary,
} from '@imprivata-cloud/adminapi-client';
import { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';
import { AddImportJobRequest } from 'src/containers/integrations/containers/data-import/containers/create-import-job/utils/models';

export function saveDataImportConfiguration$(
  request: SaveDataImportConfigurationRequest,
): Observable<boolean> {
  return promiseToObservable(
    adminApiClient.saveDataImportConfiguration(request).finally(() => true),
  );
}

export function getDataImportConfiguration$(): Observable<DataImportConfiguration | null> {
  return promiseToObservable(adminApiClient.getDataImportConfiguration()).pipe(
    map(
      (response: GetDataImportConfigurationResponse) =>
        response.configuration ?? null,
    ),
  );
}

export function addImportJob$(
  request: AddImportJobRequest,
): Observable<boolean> {
  return promiseToObservable(
    adminApiClient
      .addImportJob(
        request.file,
        request.displayName,
        request.importType,
        request.consentGranted,
        request.processingStartingWindow,
        request.processingEndingWindow,
        request.patientIdentifierOid,
      )
      .finally(() => true),
  );
}

export function getImportJobsSummary$(): Observable<
  DataImportJobSummary[] | null
> {
  return promiseToObservable(adminApiClient.getImportJobsSummary()).pipe(
    map(
      (response: GetImportJobsSummaryResponse) =>
        response.importJobsSummary ?? null,
    ),
  );
}
