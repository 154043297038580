// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import classes from './AgreeTermsModal.module.less';

interface Props {
  title: string;
  content: string;
  visible: boolean;
  closable?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onClose?: () => void;
}

const AgreeTerms: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible);

  useEffect(() => {
    setIsModalOpen(props.visible);
  }, [props.visible]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmClick = () => {
    closeModal();
    props.onSave();
  };

  const handleCancelClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isAgreeEnabled, setIsAgreeEnabled] = useState(false);

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsAgreeEnabled(true);
      }
    }
  };

  return (
    <>
      {props.visible && (
        <Modal
          open={props.visible}
          data-testid="confirm-action-modal"
          footer={null}
          onCancel={handleCancelClick}
          closable={props.closable}
          centered
        >
          <div className={classes.modalContentWraper}>
            <h2 data-testId='title-action-modal' className={classes.termsAndConditions}>
              {props.title}
            </h2>
            <div
              className={`${classes.termsAndConditionsContent} ${classes.scrollableContent}`}	
              ref={scrollableRef}
              onScroll={handleScroll}
            >
              <p>{props.content}</p>
            </div>
          </div>

          <div className={classes.modalFooterButtons}>
            <Button
              data-testid="agree-terms-modal-cancel-button"
              className="secondary"
              variant={ButtonVariant.SECONDARY}
              onClick={handleCancelClick}
              label="Disagree"
            />
            <Button
              data-testid="agree-terms-modal-confirm-button"
              variant={ButtonVariant.PRIMARY}
              onClick={handleConfirmClick}
              disabled={!isAgreeEnabled}
              label="Agree"
              autoFocus
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AgreeTerms;
