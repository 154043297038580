// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const TENANT_ID = 'impr.platform.context.tenant_id';

export const SpanNames = {
  ROOT_SPAN: 'psadminconsole' as const,

  LOGOUT: 'logout' as const,
  CREATE_USER_SESSION: 'create_user_session' as const,

  GET_TENANT_TYPE: 'get_tenant_type' as const,

  GET_DASHBOARD_TREND_DATA: 'get_dashboard_trend_data' as const,

  SEARCH_FOR_PATIENT: 'search_for_patient' as const,
  GET_PATIENT: 'get_patient' as const,
  DELETE_PATIENT: 'delete_patient' as const,
  GET_DEMOGRAPHIC_HISTORY: 'get_demographic_history' as const,
  GET_ACTIVITY_HISTORY: 'get_activity_history' as const,

  RUN_REPORT: 'run_report' as const,

  GET_ENDPOINT_INSTALLER_DATA: 'get_endpoint_installer_data' as const,

  SAVE_FHIR_CONFIGURATION: 'save_fhir_configuration' as const,
  GET_FHIR_CONFIGURATION: 'get_fhir_configuration' as const,

  SAVE_HL7_SYSTEM_CONFIGURATION: 'save_hl7_system_configuration' as const,
  GET_HL7_SYSTEM_CONFIGURATION: 'get_hl7_system_configuration' as const,
  DELETE_HL7_SYSTEM_CONFIGURATION: 'delete_hl7_system_configuration' as const,

  GET_HL7_RULE_CONFIGURATIONS: 'get_hl7_rule_configurations' as const,
  GET_HL7_RULE_CONFIGURATION: 'get_hl7_rule_configuration' as const,
  SAVE_HL7_RULE_CONFIGURATION: 'save_hl7_rule_configuration' as const,
  DELETE_HL7_RULE_CONFIGURATION: 'delete_hl7_rule_configuration' as const,

  GET_HL7_SUCCESS_MESSAGE_COUNT: 'get_hl7_success_message_count' as const,
  GET_HL7_ERRORED_MESSAGE_COUNT: 'get_hl7_errored_message_count' as const,
  GET_HL7_UNSENT_MESSAGE_COUNT: 'get_hl7_unsent_message_count' as const,

  SEARCH_HL7_MESSAGES: 'search_hl7_messages' as const,
  GET_HL7_MESSAGE: 'get_hl7_message' as const,

  GET_CONFIGURATION: 'get_configuration' as const,
  SAVE_CONFIGURATION: 'save_configuration' as const,

  GET_DATA_IMPORT_CONFIGURATION: 'get_data_import_configuration' as const,
  SAVE_DATA_IMPORT_CONFIGURATION: 'save_data_import_configuration' as const,
  DELETE_DATA_IMPORT_CONFIGURATION: 'delete_data_import_configuration' as const,

  GET_IMPORT_JOBS_SUMMARY: 'get_import_jobs_summary' as const,
  ADD_IMPORT_JOB: 'add_import_job' as const,

  GET_JWK_SET_URL: 'get_jwk_set_url' as const,
};
