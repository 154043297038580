// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DataImportJobSummary } from '@imprivata-cloud/adminapi-client';
import { ImportType } from '../containers/create-import-job/utils/models';
import moment from 'moment-timezone';
import classes from './DataImportJobsTable.module.less';
import JobStateTag from './StateTag';
import { Link } from 'react-router-dom';
import { getPathWithQuery } from '../../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../../routers/route-names';

export interface DatImportJobsTable {
  jobs: DataImportJobSummary[] | null;
  isJobsSummaryLoading: boolean;
}

interface JobSummary {
  key: string;
  id: string;
  displayName: string;
  type: string;
  detected: string;
  processed: string;
  creationDate: string;
  state: string;
}

const DataImportJobsTable: React.FC<DatImportJobsTable> = ({
  jobs,
  isJobsSummaryLoading,
}) => {
  const { t } = useTranslation();

  const data: JobSummary[] =
    jobs
      ?.map((activity, index) => {
        let importType = activity.importType ?? '';
        if (activity.importType === ImportType.PatientList) {
          importType = t(
            'data-import-configuration.jobs-table.type--enrollment-from-list',
          );
        }
        return {
          key: index.toString(),
          id: activity.id ?? '',
          displayName: activity.displayName ?? '',
          type: importType,
          detected: activity.totalDetectedNumber?.toString() ?? '',
          processed: activity.totalProcessedNumber?.toString() ?? '',
          creationDate:
            moment
              .utc(activity.createdTimestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss') ?? '',
          state: activity.status ?? '',
        };
      })
      .sort(
        (a1, a2) =>
          new Date(a2.creationDate).getTime() -
          new Date(a1.creationDate).getTime(),
      ) || [];

  const columns: TableProps<JobSummary>['columns'] = [
    {
      title: t('data-import-configuration.jobs-table.display-name'),
      dataIndex: 'displayName',
      key: 'displayName',
      width: '20%',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <span
            data-testid={`data-import-jobs--display-name-${record.key}`}
            style={{ fontWeight: 'bold' }}
          >
            {record.displayName}
          </span>
        );
      },
    },
    {
      title: t('data-import-configuration.jobs-table.type'),
      dataIndex: 'type',
      key: 'type',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <span
            data-testid={`data-import-jobs--type-${record.key}`}
            style={{
              whiteSpace: 'pre-line',
              lineHeight: 'initial',
            }}
          >
            {record.type}
          </span>
        );
      },
    },
    {
      title: t('data-import-configuration.jobs-table.detected'),
      dataIndex: 'detected',
      key: 'detected',
      width: '10%',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <span data-testid={`data-import-jobs--detected-${record.key}`}>
            {record.detected}
          </span>
        );
      },
    },
    {
      title: t('data-import-configuration.jobs-table.processed'),
      dataIndex: 'processed',
      key: 'processed',
      width: '10%',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <span data-testid={`data-import-jobs--processed-${record.key}`}>
            {record.processed}
          </span>
        );
      },
    },
    {
      title: t('data-import-configuration.jobs-table.creation-date'),
      dataIndex: 'creationDate',
      key: 'creationDate',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <span data-testid={`data-import-jobs--creation-date-${record.key}`}>
            {record.creationDate}
          </span>
        );
      },
    },
    {
      title: t('data-import-configuration.jobs-table.state'),
      dataIndex: 'state',
      key: 'state',
      width: '12%',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <JobStateTag
            data-testid={`data-import-jobs--state-${record.key}`}
            state={record.state}
          />
        );
      },
    },
    {
      title: t('data-import-configuration.jobs-table.actions'),
      width: '12%',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: JobSummary) => {
        return (
          <Link
            data-testid={`data-import-jobs--actions-${record.key}`}
            to={getPathWithQuery(integrationsRoutes.IMPORT_JOB, [
              `jobId=${record.id}`,
            ])}
          >
            {t('data-import-configuration.jobs-table.view')}
          </Link>
        );
      },
    },
  ];

  return (
    <Table
      data-testid="patient-activity-history--table"
      columns={columns}
      dataSource={data}
      tableLayout="fixed"
      pagination={false}
      loading={isJobsSummaryLoading}
    />
  );
};

export default DataImportJobsTable;
