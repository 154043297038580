// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Col, Form, Row, TimePicker, Spin } from 'antd';
import { Button, ButtonVariant, InputBox } from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'antd/lib/select';
import type { DefaultOptionType } from 'antd/lib/select';
import PageSubHeader from '../../../../../../components/page-sub-header/PageSubHeader';
import CancelButton from '../../../../../../components/action-bar/CancelButton';
import SaveDiscardModal from '../../../../../../components/save-discard-modal/SaveDiscardModal';
import classes from './CreateImportJobComponent.module.less';
import ContentCard from '../../../../../../components/content-card/ContentCard';
import { getPathWithQuery } from '../../../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../../../routers/route-names';
import SetTitle from '../../../../../../utils/DynamicTitleHelper';
import moment from 'moment-timezone';
import { addImportJob } from '../../store/facades';
import cancelIcon from '../../../../../../assets/svg/cancel-blue.svg?react';
import uploadIcon from '../../../../../../assets/svg/upload.svg?react';
import arrowIcon from '../../../../../../assets/svg/arrow.svg?react';
import timeIcon from '../../../../../../assets/svg/time.svg?react';
import Icon from '@ant-design/icons';
import { AddImportJobRequest, ImportType } from './utils/models';
import { useGetSavingJobStatus } from '../../store/hooks';
import CreateButton from '../../../../../../components/action-bar/CreateButton';
import AgreeTerms from '../../../../../../components/agree-terms-modal/AgreeTermsModal';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const defaultProcessingWindow = [
  moment().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }),
  moment().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  }),
];

const CreateDataImportJobComponent: React.FC = () => {
  const { isSavingJob } = useGetSavingJobStatus();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  // ref for file input to access the file input element easily
  const fileInput = React.useRef<HTMLInputElement>(null);

  // when it is true, the file name and remove button will be displayed
  const [hasFileUploaded, setHasFileUploaded] = useState<boolean>(false);

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [reset, setReset] = useState(false);

  const title = t('data-import-configuration.create-job.title');
  SetTitle(t(title));

  const importTypeOptions: DefaultOptionType[] = [
    {
      label: t(
        'data-import-configuration.create-job.import-type-options.patient-list',
      ),
      value: ImportType.PatientList,
    },
  ];

  const setConfigurationFormData = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    return {
      displayNameLabel: '',
      importTypeLabel: ImportType.PatientList,
      patientFhirOidLabel: '',
      patientListFileLabel: '',
      processingWindowLabel: defaultProcessingWindow,
    };
  };

  const validateAndCreate = () => {
    setIsDirty(false);

    const displayNameValue = form.getFieldValue('displayNameLabel') || '';
    const importTypeValue: ImportType =
      form.getFieldValue('importTypeLabel') || ImportType.PatientList;
    const patientFhirOidValue = (
      form.getFieldValue('patientFhirOidLabel') || ''
    ).replace(/\s+/g, '');
    const patientListFileValue =
      form.getFieldValue('patientListFileLabel') || '';
    let processingStartingWindowValue = form.getFieldValue(
      'processingWindowLabel',
    )[0] as moment.Moment | null;
    let processingEndingWindowValue = form.getFieldValue(
      'processingWindowLabel',
    )[1] as moment.Moment | null;

    if (
      processingStartingWindowValue?.format('HH:mm') === '00:00' &&
      processingEndingWindowValue?.format('HH:mm') === '23:59'
    ) {
      processingStartingWindowValue = null;
      processingEndingWindowValue = null;
    }

    if (!fileInput.current?.files?.length) {
      return;
    }

    const file = fileInput.current.files[0];

    form.setFieldsValue({
      displayNameLabel: displayNameValue,
      importTypeLabel: importTypeValue,
      patientFhirOidLabel: patientFhirOidValue,
      patientListFileLabel: patientListFileValue,
      processingWindowLabel: form.getFieldValue('processingWindowLabel'),
    });

    form.validateFields().then(() => {
      const request: AddImportJobRequest = {
        displayName: displayNameValue,
        importType: importTypeValue,
        patientIdentifierOid: patientFhirOidValue,
        consentGranted: true,
        file: {
          data: file,
          fileName: file.name,
        },
        processingStartingWindow: processingStartingWindowValue,
        processingEndingWindow: processingEndingWindowValue,
      };
      addImportJob(request, dispatch);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      form.resetFields();
      form.setFieldsValue(setConfigurationFormData());

      if (fileInput.current) {
        fileInput.current.value = '';
        fileInput.current.files = null;
      }
      setReset(!reset);
    }

    setIsSaveDisabled(true);
    setIsDirty(false);
  };

  const handleChange = () => {
    setIsSaveDisabled(false);
    form.validateFields().catch(e => {
      if (e.errorFields.length > 0) {
        setIsSaveDisabled(true);
      }
    });

    setIsDirty(true);
  };

  const [isAgreeTermsVisible, setIsAgreeTermsVisible] = useState(false);

  const handleCreateClick = () => {
    setIsAgreeTermsVisible(true);
  };

  const handleCancelClick = () => {
    setIsAgreeTermsVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue(setConfigurationFormData());
  }, []);

  const preventWhitespaceOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    /\s+/g.test(e.key) && e.preventDefault();
  };

  const preventWhitespaceOnPaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text
    const pastedText = event.clipboardData.getData('text');

    // Remove whitespaces from the pasted text
    const sanitizedText = pastedText.replace(/\s+/g, '');

    // Update the input field with the sanitized text
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart ?? 0;

    const newValue =
      inputElement.value.slice(0, cursorPosition) +
      sanitizedText +
      inputElement.value.slice(cursorPosition);

    inputElement.value = newValue;

    // Restore the cursor position
    const newCursorPosition = cursorPosition + sanitizedText.length;
    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);

    form.setFieldValue('patientFhirOidLabel', newValue);
  };

  const validatePatientListFileInput = async (_: any, __: any) => {
    if (
      fileInput?.current?.files?.length &&
      fileInput.current.files.length > 0
    ) {
      const file = fileInput.current.files[0];
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB === 0) {
        return Promise.reject(
          new Error(
            t('data-import-configuration.create-job.errors.empty-file'),
          ),
        );
      }
      if (fileSizeMB >= 3) {
        return Promise.reject(
          new Error(t('data-import-configuration.create-job.errors.file-size')),
        );
      }
      if (!file.name.endsWith('.csv')) {
        return Promise.reject(
          new Error(t('data-import-configuration.create-job.errors.file-type')),
        );
      }

      // validate file does not have whitespaces only
      const fileContent = await file.text();
      const sanitizedFileContent = fileContent.replace(/\s+/g, '');
      if (sanitizedFileContent.length === 0) {
        return Promise.reject(
          new Error(
            t('data-import-configuration.create-job.errors.empty-file'),
          ),
        );
      }
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error(
          t('data-import-configuration.create-job.errors.file-required'),
        ),
      );
    }
  };

  return (
    <>
    <AgreeTerms
        title={t(
          'data-import-configuration.create-job.agree-to-terms',
        )}
        onCancel={handleCancelClick}
        content={"Terms and conditions content Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,"}
        visible={isAgreeTermsVisible}
        onSave={() => validateAndCreate()}
      />

      <Breadcrumb style={{ marginBottom: '10px' }}>
        <Breadcrumb.Item>
          <Link to={getPathWithQuery(integrationsRoutes.DATA_IMPORT)}>
            {t('navigation.data-import-configuration')}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>

      <PageSubHeader
        title={title}
        extra={[
          <CancelButton
            key="cancel-button"
            onClick={() => resetForm()}
            disabled={!isDirty}
          />,
          <CreateButton
            key="create-button"
            onClick={() => handleCreateClick()}
            disabled={isSaveDisabled}

          />,
        ]}
      />
      <SaveDiscardModal
        title={t('data-import-configuration.create-job.leave-page-modal.title')}
        cancelText={t(
          'data-import-configuration.create-job.leave-page-modal.leave-btn',
        )}
        okText={t(
          'data-import-configuration.create-job.leave-page-modal.stay-btn',
        )}
        content={t(
          'data-import-configuration.create-job.leave-page-modal.content',
        )}
        open={isDirty}
        okButtonClosesModal
        onSave={() => {}}
        onDiscard={() => resetForm()}
      ></SaveDiscardModal>
      {isSavingJob && (
        <div className={classes.spinner}>
          <Spin size="large" />
        </div>
      )}
      <ContentCard
        {...(isSavingJob && {
          style: { display: 'none' },
        })}
      >
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="register"
          onChange={handleChange}
          className={classes.createJobForm}
        >
          <div>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="create-import-job-form--display-name-label"
                  name="displayNameLabel"
                  label={t('data-import-configuration.create-job.display-name')}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'data-import-configuration.create-job.errors.display-name-required',
                      ),
                    },
                  ]}
                >
                  <InputBox
                    data-testid="create-import-job-form--display-name"
                    className={classes.input}
                    size="small"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="create-import-job-form--import-type-label"
                  name="importTypeLabel"
                  className="impr-select-wrapper"
                  initialValue={importTypeOptions[0].label}
                  label={t('data-import-configuration.create-job.import-type')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    data-testid="create-import-job-form--import-type"
                    options={importTypeOptions}
                    className="impr-select-container"
                    disabled={importTypeOptions.length <= 1}
                    id="disabledImportTypeSelect"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="create-import-job-form--patient-fhir-oid-label"
                  name="patientFhirOidLabel"
                  label={t(
                    'data-import-configuration.create-job.patient-fhir-oid',
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'data-import-configuration.create-job.errors.patient-fhir-oid-required',
                      ),
                    },
                    {
                      pattern: /^urn:oid:.*/,
                      message: t(
                        'data-import-configuration.create-job.errors.invalid-patient-fhir-oid',
                      ),
                    },
                  ]}
                >
                  <InputBox
                    data-testid="create-import-job-form--patient-fhir-oid"
                    className={classes.input}
                    placeholder=""
                    size="small"
                    type="text"
                    pattern="^\S*$"
                    onKeyDown={preventWhitespaceOnKeyDown}
                    onPaste={preventWhitespaceOnPaste}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="create-import-job-form--patient-list-file-label"
                  name="patientListFileLabel"
                  htmlFor="file"
                  label={t(
                    'data-import-configuration.create-job.patient-list-file',
                  )}
                  required
                  rules={[
                    {
                      validator: validatePatientListFileInput,
                    },
                  ]}
                  onReset={() => {
                    setHasFileUploaded(false);
                  }}
                >
                  <Row align={'middle'}>
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      className="secondary"
                      data-testid="create-import-job-form--upload-csv-btn"
                      onClick={() => fileInput.current?.click()}
                      icon={<Icon component={uploadIcon} />}
                      label={t(
                        'data-import-configuration.create-job.upload-csv-btn',
                      )}
                      id="uploadButton"
                    />
                    <input
                      data-testid="create-import-job-form--upload-csv-input"
                      type="file"
                      id="file"
                      name="file"
                      accept=".csv"
                      ref={fileInput}
                      onChange={_ => {
                        setHasFileUploaded(true);
                        handleChange();
                      }}
                      {...(!hasFileUploaded && {
                        style: { display: 'none' },
                      })}
                    />
                    {hasFileUploaded && (
                      <Button
                        data-testid="create-import-job-form--remove-file-btn"
                        id={classes.cancelButton}
                        className={classes.cancelButton}
                        icon={
                          <Icon
                            component={cancelIcon}
                            className={classes.cancelUploadIcon}
                          />
                        }
                        onClick={() => {
                          if (fileInput?.current) {
                            form.resetFields(['patientListFileLabel']);
                            setHasFileUploaded(false);
                            handleChange();
                          }
                        }}
                      />
                    )}
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="create-import-job-form--processing-window-label"
                  name="processingWindowLabel"
                  label={t(
                    'data-import-configuration.create-job.processing-window',
                  )}
                >
                  <TimePicker.RangePicker
                    data-testid="create-import-job-form--processing-window"
                    defaultValue={[
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm'),
                    ]}
                    order={false}
                    format={'HH:mm'}
                    size={'large'}
                    allowClear={false}
                    suffixIcon={<Icon component={timeIcon} />}
                    separator={
                      <Icon
                        component={arrowIcon}
                        className={classes.arrowSeparator}
                      />
                    }
                    className={classes.timePicker}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </ContentCard>
    </>
  );
};

export default CreateDataImportJobComponent;
