// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type {
  DataImportConfiguration,
  DataImportJobSummary,
} from '@imprivata-cloud/adminapi-client';
import type { RootState } from '../../../../../store/rootReducer';

export const isLoadingSelector = ({
  dataImportConfiguration,
}: RootState): boolean => dataImportConfiguration.loading;

export const isJobsSummaryLoadingSelector = ({
  dataImportConfiguration,
}: RootState): boolean => dataImportConfiguration.jobsSummaryLoading;

export const isSavingJobSelector = ({
  dataImportConfiguration,
}: RootState): boolean => dataImportConfiguration.savingJob;

export const dataImportConfigurationSelector = ({
  dataImportConfiguration,
}: RootState): DataImportConfiguration | null =>
  dataImportConfiguration.configuration;

export const dataImportJobsSummarySelector = ({
  dataImportConfiguration,
}: RootState): DataImportJobSummary[] | null =>
  dataImportConfiguration.dataImportJobsSummary;
